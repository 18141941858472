import React from "react"

export default function Work() {
  return (
    <section data-scroll-section className="work-section" id="work">
      <p className="main-head font-secondary">Selected Work</p>
      <div className="work-list">
        {[
          {
            number: "01.",
            name: "heeko.com",
            image: "/images/work/heeko.webp",
            paddingBottom: "124.5%",
            work: "Interactions & frontend",
            link: "https://heeko.com",
            speed: "1",
          },
          {
            number: "02.",
            name: "italic.com",
            image: "/images/work/italic.webp",
            paddingBottom: "125%",
            work: "UI design & frontend",
            link: "https://italic.com",
            speed: "2",
          },
          {
            number: "03.",
            name: "3dmade.design",
            image: "/images/work/3dmade.webp",
            paddingBottom: "100%",
            work: "Frontend development",
            link: "https://3dmade.design",
            speed: "3",
          },
          {
            number: "04.",
            name: "notpot.com",
            paddingBottom: "100%",
            image: "/images/work/notpot.webp",
            work: "Interaction design & frontend",
            link: "https://notpot.com",
            speed: "2",
          },
          {
            number: "05.",
            name: "atomizecode.com",
            image: "/images/work/atomizecode.webp",
            paddingBottom: "70.9%",
            work: "Design & development",
            link: "https://atomizecode.com",
            speed: "1",
          },
        ].map((item, index) => (
          <div
            data-scroll
            data-scroll-speed={item.speed}
            key={`${item.name}-${index}`}
            className="work"
          >
            <WorkItem item={item} />
          </div>
        ))}
      </div>
    </section>
  )
}

const WorkItem = ({ item }) => {
  return (
    <a href={item.link} target="_blank" className="work-item cursor-hover">
      <div className="work-image" style={{ paddingBottom: item.paddingBottom }}>
        <div
          className="image"
          style={{
            backgroundImage: `url("${item.image}")`,
          }}
        />
      </div>
      <div className="info">
        <p className="number font-secondary">{item.number}</p>
        <div className="info-main">
          <p className="work-name font-secondary">{item.name}</p>
          <p className="work-type">{item.work}</p>
        </div>
      </div>
    </a>
  )
}
