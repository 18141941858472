import React from "react"
import { useLocomotiveScroll } from "react-locomotive-scroll"

export default function Header() {
  const { scroll } = useLocomotiveScroll()

  return (
    <header>
      <div
        data-scroll-section
        className="marquee-wrapper font-secondary w-100 py-1 border-bottom"
      >
        <div className="marquee-1">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
            <>
              <span className="h5 text-uppercase">Portfolio 2021</span>
              <span className="h5 text-uppercase px-2">/</span>
            </>
          ))}
        </div>
        <div className="marquee-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
            <>
              <span className="h5 text-uppercase">Portfolio 2021</span>
              <span className="h5 text-uppercase px-2">/</span>
            </>
          ))}
        </div>
      </div>
      <div className="header-container">
        <img className="logo" src="/images/logo.svg" alt="Proksh Luthra" />
        <div className="header-menu">
          <div
            onClick={() => scroll.scrollTo("#aboutMe")}
            className="h6 cursor-hover text-uppercase"
          >
            About me
          </div>
          <div
            onClick={() => scroll.scrollTo("#work")}
            className="h6 cursor-hover text-uppercase"
          >
            Work
          </div>
          <div
            onClick={() => scroll.scrollTo("footer")}
            className="h6 cursor-hover text-uppercase get-in-touch"
          >
            Get in touch
            <img className="icon" src="/images/right-link-arrow.svg" />
          </div>
        </div>
      </div>
    </header>
  )
}
