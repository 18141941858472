import React, { useEffect } from "react"
import Layout from "../components/common/Layout"
import Hero from "../components/home/Hero"
import Work from "../components/home/Work"
import Footer from "../components/common/Footer"

export default function Home() {
  useEffect(() => {
    const body = document.querySelector("body")
    if (body) {
      body.classList.add("loaded")
    }
  }, [])

  return (
    <Layout>
      <Hero />
      <Work />
      <Footer />
    </Layout>
  )
}
