import React, { useRef, useEffect } from "react"
import { TweenMax } from "gsap"

export default function Hero() {
  const cursorChaser = useRef(null)
  let mousePosition = {
    x: 0,
    y: 0,
  }

  // Set Value on Mouse Move
  // function onMouseMove(e) {
  //   setTimeout(() => {
  //     const windowHeight = window.innerHeight
  //     const windowWidth = window.innerWidth

  //     mousePosition = {
  //       x: (e.clientX - windowWidth / 2) / 3,
  //       y: (e.clientY - windowHeight / 2) / 3,
  //     }
  //   }, 200)
  // }

  function animate() {
    TweenMax.set(cursorChaser.current, {
      x: mousePosition.x,
      y: mousePosition.y,
    })
    requestAnimationFrame(animate)
  }

  // function setCursorFollower() {
  // window.addEventListener("mousemove", onMouseMove)
  //   requestAnimationFrame(animate)
  // }

  // useEffect(() => {
  //   setCursorFollower()

  //   return function cleanup() {
  // window.removeEventListener("mousemove", onMouseMove)
  //   }
  // }, [])

  return (
    <section data-scroll-section className="hero-section">
      <div className="mobile-image" />
      <div className="position-relative" id="aboutMe">
        <p className="main-head main-head-1 font-secondary">
          designer<span>and</span>
        </p>
        <p className="main-head main-head-2 font-secondary">developer</p>
        {/* <div className="background1" />
        <div className="background2" /> */}
        <div ref={cursorChaser} className="gradient-cursor">
          <div className="gradient" />
        </div>
      </div>
      <div className="row">
        <div className="col-1">
          <div className="desktop-image" />
        </div>

        <div className="col-2">
          <p className="text">
            Hello 👋🏻. My name is Proksh Luthra — currently living in New Delhi,
            India. I am a passionate and creative UI / UX Designer and Frontend
            developer with keen interest in building highly polished user
            experiences. I enjoy working with cross - disciplinary teams to
            create, innovate and deliver.
          </p>
        </div>
      </div>
    </section>
  )
}
