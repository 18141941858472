import React from "react"

export default function Footer() {
  return (
    <footer data-scroll-section>
      <div className="footer-wrapper position-relative w-100">
        <div className="main-col">
          <div className="follow-me">
            <div className="background1" />
            <div className="background2" />
            <p className="head">Follow me</p>
            <a
              href="https://dribbble.com/proksh"
              target="_blank"
              className="link cursor-hover font-secondary"
            >
              Dribbble
            </a>
            <a
              href="https://www.linkedin.com/in/prokshluthra/"
              target="_blank"
              className="link cursor-hover font-secondary"
            >
              Linkedin
            </a>
            <a
              href="https://github.com/proksh"
              target="_blank"
              className="link cursor-hover font-secondary"
            >
              Github
            </a>
            <a
              href="https://twitter.com/proksh_luthra"
              target="_blank"
              className="link cursor-hover font-secondary"
            >
              Twitter
            </a>
          </div>
          <a
            href="mailto:prokshh@gmail.com"
            className="mailto cursor-hover font-secondary underline-text"
          >
            or say hello
          </a>
        </div>
        <div className="all-right-reserved">
          <div className="image" />
          <div className="text">©2021. ALL RIGHTS RESERVED.</div>
        </div>
      </div>
      <div className="marquee-wrapper font-secondary w-100 py-1 border-top">
        <div className="marquee-1">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
            <>
              <span className="h5 text-uppercase">Portfolio 2021</span>
              <span className="h5 text-uppercase px-2">/</span>
            </>
          ))}
        </div>
        <div className="marquee-2">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(() => (
            <>
              <span className="h5 text-uppercase">Portfolio 2021</span>
              <span className="h5 text-uppercase px-2">/</span>
            </>
          ))}
        </div>
      </div>
    </footer>
  )
}
