import React, { useEffect, useRef } from "react"
import Header from "./Header"
import { gsap, TweenMax, Back } from "gsap"
import { LocomotiveScrollProvider } from "react-locomotive-scroll"

import "../../styles/scss/style.scss"

export default function Layout({ children }) {
  const containerRef = useRef(null)
  let easing = Back.easeInOut.config(1.7)
  const cursor = useRef(null)
  const cursorSmall = useRef(null)
  const fullCursorSize = 40
  let mousePosition = {
    x: 0,
    y: 0,
    hasMoved: 0,
  }

  // Set Value on Mouse Move
  function onMouseMove(e) {
    mousePosition = {
      x: e.clientX,
      y: e.clientY,
      hasMoved: 1,
    }
  }

  // Animations
  function animate() {
    if (cursor.current) {
      TweenMax.set(".cursor-wrapper", {
        x: mousePosition.x,
        y: mousePosition.y,
        opacity: mousePosition.hasMoved,
      })
    }
    requestAnimationFrame(animate)
  }

  function initCursorAnimation() {
    // Hover Tween
    var enlargeCursorTween = TweenMax.to(cursorSmall.current, 0.3, {
      width: fullCursorSize,
      height: fullCursorSize,
      ease: easing,
      paused: true,
    })

    const handleMouseEnter = () => {
      enlargeCursorTween.play()
    }

    const handleMouseLeave = () => {
      enlargeCursorTween.reverse()
    }

    const hoverItems = document.querySelectorAll(".cursor-hover")
    hoverItems.forEach(el => {
      el.addEventListener("mouseenter", handleMouseEnter)
      el.addEventListener("mouseleave", handleMouseLeave)
    })
  }

  useEffect(() => {
    gsap.config({ nullTargetWarn: false })
    window.addEventListener("mousemove", onMouseMove)
    initCursorAnimation()
    requestAnimationFrame(animate)

    return function cleanup() {
      window.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  return (
    <>
      <div ref={cursor} className="cursor-wrapper">
        <div ref={cursorSmall} className="cursor-small"></div>
      </div>
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          // ... all available Locomotive Scroll instance options
        }}
        watch={
          [
            //...all the dependencies you want to watch to update the scroll
          ]
        }
        containerRef={containerRef}
      >
        <main data-scroll-container ref={containerRef}>
          <Header />
          {children}
        </main>
      </LocomotiveScrollProvider>
    </>
  )
}
